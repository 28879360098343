import GlobalStore from '@/GlobalStore';
import "@/common/styles/ag-theme-vcat.scss";
import LDClientProvider from "@/infra/launchdarkly/LDClientProvider";
import Router from "@/router/Router";
import FontService from "@/service/FontService";
import { ThemeProvider } from '@mui/material/styles';
import "ag-grid-community/dist/styles/ag-grid.css";
import { LicenseManager } from "ag-grid-enterprise";
import { useEffect, useState } from "react";
import { RecordTheme } from './RecordTheme';

const ag = LicenseManager.setLicenseKey("RECORD_MTkyODM3NDAwMDAwMA==63673dc8b691f574a6ccfac9315d778a");
// ModuleRegistry.register(ClipboardModule as any);

const App = () => {
    const [user] = GlobalStore.use((state) => [state.user]);
    const [_fontLoaded, setFontLoaded] = useState(false);

    useEffect(() => {
        (async () => {
            if (user) {
                await FontService.load();
                setFontLoaded(true);
            } else {
                FontService.clear();
                setFontLoaded(false);
            }
        })();
    }, [user]);

    return (
        <ThemeProvider theme={RecordTheme}>
            <LDClientProvider>
                <Router />
            </LDClientProvider>
        </ThemeProvider >
    );
};

export default App;